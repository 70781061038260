import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ThemeDirective} from '@app/components/core/utils/theme/theme.directive';
import {DlScrollDirective} from '@app/directives/dl-scroll-directive';
import {DlTabWheelScrollDirective} from '@app/directives/dl-tab-mouse-wheel-scroll.directive';
import {DlLoaderDirective} from "@app/directives/dl-loader.directive";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [ThemeDirective, DlScrollDirective, DlTabWheelScrollDirective, DlLoaderDirective],
  exports:      [ThemeDirective, DlScrollDirective, DlTabWheelScrollDirective, DlLoaderDirective],
  providers: []
})
export class ThemeModule { }
