import {Directive, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {ThemeService} from '@app/services/core/theme.service';
import {themes} from '@app/components/core/utils/theme/theme-vars';
import {Globals} from '@dragleuz/session';
import {environment} from '@env/environment';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

  private name = 'dark';

  dark;
  light;
  subs: Subscription = new Subscription();

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: any, private theme: ThemeService, private global: Globals) {
  }

  ngOnInit() {
    this.dark = 'dark_' + environment.org;
    this.light = 'light_' + environment.org;

    if (localStorage.getItem('theme') == this.dark) {
      this.global.setTheme(this.dark);
      localStorage.setItem('theme', this.dark);
    } else {
      this.global.setTheme(this.light);
      localStorage.setItem('theme', this.light);
    }
    this.subs.add(this.global.getTheme().subscribe(themeName => {
      this.updateTheme(themeName);
    }));
  }

  updateTheme(themeName) {
    const keys = themes[themeName];
    for (const key in keys) {
      this.document.body.style.setProperty(key, keys[key]);
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
