import {Component, OnInit} from '@angular/core';
import {AuthService, Globals, HttpService, MsgService} from '@dragleuz/session';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  template: ''
})
export class PreSession implements OnInit {

  constructor(
    public global: Globals,
    private http: HttpService,
    private auth: AuthService,
    private msg: MsgService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.initSession();
  }

  initSession() {
    if (this.route.snapshot.queryParams.ref) {
      this.http.post({sessionId: this.route.snapshot.queryParams.ref}, 'auth/session').subscribe(d => {
        localStorage.setItem('token', d.token);
        this.router.navigate([this.route.snapshot.queryParams.url]);
      });
    }
  }
}
