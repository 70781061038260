import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';

if (environment.production) {
  enableProdMode();
}

if (window) {
  window.console.log = environment.enableConsole ? window.console.log : (elementById: any) => {};
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
