import {AfterViewChecked, Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {NgxUiLoaderService, POSITION} from 'ngx-ui-loader';
import {AuthService, DateService, Globals, HttpService, MsgService, UserService} from '@dragleuz/session';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {environment} from '@env/environment';
import {DlTimeoutService} from '@dragleuz/components';
import {Subscription} from 'rxjs';
import {CoreOperDayService} from '@app/services/core/core-oper-day.service';
import {MatDialog} from '@angular/material/dialog';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  align = POSITION.centerLeft;
  subs: Subscription = new Subscription();
  isToastOn: boolean = false;
  missedMessages: any[] = [];

  constructor(
    public auth: AuthService,
    private route: Router,
    private msg: MsgService,
    private ngxLoader: NgxUiLoaderService,
    private http: HttpService,
    private sOperDay: CoreOperDayService,
    private date: DateService,
    private serTimeOut: DlTimeoutService,
    private user$: UserService,
    public dialog: MatDialog,
    public globals: Globals,
    @Inject(DOCUMENT) private document,
    @Inject('env') private env,
  ) {
  }

  fetchOperDay() {
    if (this.auth.isAuthed()) {
      this.subs.add(this.http.exec('util/get_operday').subscribe(data => {
        if (data.success && data.operday) {
          this.sOperDay.setOperDay(this.date.toJsDate(data.operday));
        }
      }));
    }
  }

  async ngOnInit() {
    this.setFavicon();
    await this.serTimeOut.run(this.subs);
    this.fetchOperDay();
    this.ngxLoader.startLoader('core-loader');
    this.setSize();
    let ang = this;
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        ang.setSize();
      }, 100);
    }, true);
    await this.isAuthed();
    this.ngxLoader.stopLoader('core-loader');
    let bases = this.document.getElementsByTagName('base');
    if (bases.length > 0) {
      bases[0].setAttribute('href', environment.baseHref);
    }
    this.subs.add(
      this.auth.isAuthed$.subscribe(s => {
        setTimeout(() => {
          if (s) {
            this.document.location.reload();
          }
        }, 100);
      }));
  }

  setFavicon() {
    let element = document.getElementById('favicon');
    if (element && this.env.production) {
      element['href'] = `./assets/img/logo/${this.env.org}/favicon.ico`;
    } else {
      element['href'] = `./assets/img/favicon.ico`;
    }
  }

  ngAfterViewInit() {
    document.getElementById('bg-logo-main').style.width = '40%';
    document.getElementById('bg-logo-main').style.opacity = '.4';
  }

  ngAfterViewChecked(): void {
    this.resize();
  }

  setSize() {
    let body = $('body');
    this.subs.add(this.route.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data) => {
      if (data['url'] === '/auth') {
        $('#mainContent').height($(window).height());
        body.css({height: '100vh'});
      } else {
        body.css({height: '100%'});
      }
    }));
  }

  async isAuthed() {
    this.subs.add(this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data) => {
      return data['url'] == '/auth';
    }));
  }

  resize() {
    if (this.auth.isAuthed()) {
      $('#mainContent').height($(window).height());
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.serTimeOut.onMouseMove();
    if (event.ctrlKey && event.code == 'KeyQ') {
      this.auth.logout();
      this.serTimeOut.getDialog().closeAll();
    } else if (event.ctrlKey && event.code == 'KeyL') {
      this.serTimeOut.setCanUse(false);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove = (event: KeyboardEvent) => this.serTimeOut.onMouseMove();

  windowClick(evt) {
    let ang = this;
    if (document.getElementsByTagName('snack-bar-container').length > 0) {
      if ($(document.getElementsByTagName('snack-bar-container')[0]).has($(evt.target)).length == 0 && !$(document.getElementsByTagName('snack-bar-container')[0]).is($(evt.target))) {
        ang.msg.hide();
      }
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
