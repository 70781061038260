export const environment = {
  production: false,
  fRoot: "https://demo-api.dragle.uz",
  root: "https://demo-api.dragle.uz",
  port: 443,
  dl_gram_url: 'http://localhost:9899/',
  dlgram: false,
  dlgram_reconnect_count: 1,
  url: "/",
  baseHref: "/",
  org: "xb",
  spinner: {
    url: 'assets/img/loader-xb.svg',
    size: 120
  },
  enableConsole: true,
  grid_v2: true,
};
