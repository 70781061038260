import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/services/guards/auth.guard';
import {PreSession} from "./components/core/utils/pre-session";

const routes: Routes = [
  {path: '', canActivate: [AuthGuard], loadChildren: () => import('@app/modules/core/core-main.module').then(m => m.CoreMainModule)},
  {path: 'redirect', component: PreSession},
  {path: 'auth', loadChildren: () => import('@app/modules/core/core-auth.module').then(m => m.CoreAuthModule)},
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/core/core-settings.module').then(m => m.CoreSettingsModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/core/core-profile.module').then(m => m.CoreProfileModule)
  },
  {path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('@modules/core/admin.module').then(m => m.AdminModule)},
  {path: 'mfi', canActivate: [AuthGuard], loadChildren: () => import('@modules/mid/mid.module').then(m => m.MidModule)},
  {
    path: 'bi-panel',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/bi/bi.panel.module').then(m => m.BiPanelModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'bi-admin',
    canActivate: [AuthGuard],
    data: {test: 1},
    loadChildren: () => import('@modules/bi/bi.admin.module').then(m => m.BiAdminModule)
  },
  {
    path: 'user-messages',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/core/core-messages.module').then(m => m.CoreMessagesModule)
  },
  {path: 'accounting', canActivate: [AuthGuard], loadChildren: () => import('@modules/acc/acc.module').then(m => m.AccModule)},
  {path: 'cd', canActivate: [AuthGuard], loadChildren: () => import('@modules/cd/cd.module').then(m => m.CdModule)},
  {path: 'bm', canActivate: [AuthGuard], loadChildren: () => import('@modules/bm/bm.module').then(m => m.BmModule)},
  {path: 'securities_accounting', canActivate: [AuthGuard], loadChildren: () => import('@modules/sac/sac.module').then(m => m.SacModule)},
  {path: 'dp', canActivate: [AuthGuard], loadChildren: () => import('@modules/dp/dp.module').then(m => m.DpModule)},
  {path: 'gsb', canActivate: [AuthGuard], loadChildren: () => import('@modules/gsb/gsb.module').then(m => m.GsbModule)},
  {path: 'dl', canActivate: [AuthGuard], loadChildren: () => import('@modules/dl/dl.module').then(m => m.DlModule)},
  {path: 'fo', canActivate: [AuthGuard], loadChildren: () => import('@modules/fo/fo.module').then(m => m.FoModule)},
  {
    path: 'dl-autocomplete',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/components/dl-autocomplete/dl-autocomplete.module').then(m => m.DlAutocompleteModule)
  },
  {path: '**', redirectTo: 'auth', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
