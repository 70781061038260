import {BrowserModule, HammerModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '@app/app-routing.module';
import {AppComponent} from '@app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ThemeModule} from '@app/components/core/utils/theme/theme.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@env/environment';
import {HttpLoaderFactory, LoaderProperties} from '@app/configs/common.configs';
import {MaterialModule} from '@app/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SessionModule} from '@dragleuz/session';
import {DlNavbarModule, FooterModule} from '@dragleuz/components';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from '@app/services/core/http-interceptor';
import {SocketIoModule} from "ngx-socket-io";
import {PreSession} from "./components/core/utils/pre-session";

@NgModule({
  declarations: [
    AppComponent,
    PreSession
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SessionModule.forRoot(environment),
    MatSnackBarModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(LoaderProperties),
    TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: (HttpLoaderFactory), deps: [HttpClient]}}),
    ThemeModule,
    ServiceWorkerModule.register('  ngsw-worker.js', {enabled: environment.production}),
    HammerModule,
    AppRoutingModule,
    DlNavbarModule,
    FooterModule,
    SocketIoModule
  ],
  providers: [
    Title,
    // DlNavbar1,
    // MatTabNav,
    {provide: CUSTOM_ELEMENTS_SCHEMA, useValue: CUSTOM_ELEMENTS_SCHEMA},
    [{provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true}],
    [{provide: DEFAULT_TIMEOUT, useValue: 1800000}]
  ], bootstrap: [AppComponent]
})
export class AppModule {
}

// platformBrowserDynamic().bootstrapModule(AppModule);
