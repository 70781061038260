import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class CoreOperDayService {

  protected operDay$: BehaviorSubject<Date> = new BehaviorSubject(new Date);
  _operDay: Observable<Date> = this.operDay$.asObservable();


  public get operDay(): Observable<Date> {
    return this._operDay;
  }

  public setOperDay(date) {
    this.operDay$.next(date);
  }

}
